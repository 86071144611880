.App {
  text-align: center;
  height: 100%;
  background-color: gainsboro;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  position: relative;
}

.image-ticker {
  width: 100%;
  overflow-x: clip;
}

.image-ticker-container {
  display: flex;
  animation: ticker-animation 60s linear infinite; /* Adjust animation duration as needed */
}

.image-ticker-container:hover {
  animation-play-state: paused; /* Pause the animation when hovering over an image */
}

@keyframes ticker-animation {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(calc(-144rem));
  }
}

.image-ticker-container img {
  max-width: 16rem;
  height: auto;
  transition: transform 0.3s ease; /* Smooth transition for hover effect */
  position: relative;
}

.image-ticker-container img:hover {
  transform: scale(1.3); /* Enlarge the image on hover */
  z-index: 2; /* Ensure enlarged image is on top of others */
  cursor: pointer; /* Change cursor on hover */
  animation-play-state: paused; /* Pause the animation on hover */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  transition: opacity 0.3s ease;
  pointer-events: none;
  z-index: 1;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}